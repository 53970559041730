<template>
  <div>
    <input
      :id="inputId"
      ref="input"
      type="file"
      name="files[]"
      class="dropzone__input"
      :accept="dropzone.accept"
      :multiple="isMultiple"
      @change="onInputChange"
    >
    <label
      ref="dropzone"
      class="dropzone__label"
      :class="{'dropzone__label_error' : !isValid}"
      :for="inputId"
    >
      <slot class="dropzone__icon" />
    </label>
    <div class="dropzone__size font font_xs font_grey">
      Максимальный размер файла {{ maxFilesize }}МБ
    </div>
  </div>
</template>

<script>
import {getRandomIntInclusive} from '~/plugins/generators';

export default {
  name: 'CustomDropzoneInput',
  props: {
    dropzone: {
      type: Object,
      required: true,
    },
    isValid: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputId: `dropzone__input${getRandomIntInclusive(1, 1000000)}`,
    }
  },
  computed: {
    maxFilesize() {
      return this.dropzone.fileSize / 1000
    },
    isMultiple() {
      return this.dropzone.fileNum > 1
    },
  },
  methods: {
    onInputChange(e) {
      this.$emit('filesChange', [ ...e.target.files ])
      e.target.value = null
    },
  },
}
</script>
