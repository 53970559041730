<template>
  <ul class="image-list">
    <CustomDropzonePreviewItem
      v-for="file of files"
      :key="file.id"
      :file="file"
      @remove="$emit('remove', $event)"
    />
  </ul>
</template>

<script>
import CustomDropzonePreviewItem from '~/components/elements/CustomDropzone/Preview/Item';
export default {
  name: 'CustomDropzonePreview',
  components: {CustomDropzonePreviewItem},
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
}
</script>
