<template>
  <section class="layout-with-bar preferences">
    <div class="layout-with-bar__main profile-promocodes__main">
      <div class="profile-promocodes__controls">
        <div class="profile-promocodes__controls-type">
          <Tabs @change="switchIsArchive">
            <TabItem title="Действующие" />
            <TabItem title="Архив" />
          </Tabs>
        </div>
        <div class="profile-promocodes__controls-request">
          <Button @click="showRequestModal">
            Запрос промокода
          </Button>
        </div>
      </div>
      <table v-if="items.length" class="profile-promocodes-table">
        <colgroup>
          <col width="20%" />
          <col width="20%" />
          <col width="15%" />
          <col width="30%" />
        </colgroup>
        <thead class="profile-promocodes-table__header">
          <tr class="profile-promocodes-table__row profile-promocodes-table__row--header">
            <th class="profile-promocodes-table__head" align="left">
              Промокод
            </th>
            <th class="profile-promocodes-table__head" align="left">
              Срок действия
            </th>
            <th class="profile-promocodes-table__head" align="left">
              Скидки
            </th>
            <th class="profile-promocodes-table__head" align="left">
              Категория товаров/услуг
            </th>
          </tr>
        </thead>
        <tbody class="profile-promocodes-table__body">
          <tr v-for="promocode in items" :key="promocode.code" class="profile-promocodes-table__row">
            <td class="profile-promocodes-table__column" align="left" @click="onCopyToClipboard(promocode.code)">
              <div class="profile-promocodes-table__column-promocode">
                {{ promocode.code }}
                <svg-icon name="copy" width="16" height="16" />
              </div>
            </td>
            <td class="profile-promocodes-table__column" align="left">
              <span v-if="!promocode.endDate && promocode.startDate">c </span>
              {{ promocode.startDate }}
              <span v-if="promocode.startDate && promocode.endDate"> - </span>
              <template v-else-if="!promocode.startDate && !promocode.endDate">
                Бессрочный
              </template>
              <span v-if="!promocode.startDate && promocode.endDate">по </span>
              {{ promocode.endDate }}
            </td>
            <td class="profile-promocodes-table__column" align="left">
              {{ promocode.discount ? promocode.discount : '-' }}
            </td>
            <td class="profile-promocodes-table__column" align="left">
              <template v-if="promocode.type === promocodeType.DELIVERY">
                Бесплатная доставка
              </template>
              <template v-if="promocode.type === promocodeType.GIFT">
                Подарок
              </template>
              <template v-else-if="promocode.type === promocodeType.BONUS">
                Бонусы
              </template>
              <template v-else-if="promocode.type === promocodeType.PRODUCT">
                <template v-if="promocode.discountType === promocodeDiscountType.DELIVERY">
                  Скидка на доставку
                </template>
                <template v-else-if="promocode.discountType === promocodeDiscountType.CART">
                  Скидка на сумму корзины
                </template>
                <template v-else-if="promocode.discountType === promocodeDiscountType.BUNDLE">
                  Скидка на комплект
                </template>
                <template v-else-if="promocode.discountType === promocodeDiscountType.OFFER">
                  <div
                    v-for="key in Object.keys(promocode.offers)"
                    :key="key"
                    class="promocodes-view__category-panel"
                  >
                    <button
                      class="promocodes-view__category-panel-header-btn"
                      @click="onToggleIsOpen(promocode.offers[key])"
                    >
                      {{ key }}:
                      <SvgIcon
                        class="promocodes-view__category-panel-icon"
                        :class="{ 'promocodes-view__category-panel-icon_rotate': promocode.offers[key].isOpen }"
                        name="arrow-down"
                        width="8"
                        height="8"
                      />
                    </button>
                    <ul
                      v-if="promocode.offers[key].isOpen"
                      class="promocodes-view__category-panel-list"
                    >
                      <li v-for="offer in promocode.offers[key].items" :key="offer.name">
                        - {{ offer.name }}
                      </li>
                    </ul>
                  </div>
                </template>
                <div v-else class="promocodes-view__category-panel">
                  <template v-if="promocode.discountType === promocodeDiscountType.BRAND">
                    <button
                      class="promocodes-view__category-panel-header-btn"
                      @click="onToggleIsOpen(promocode.brands)"
                    >
                      Бренды: &nbsp;
                      <SvgIcon
                        class="promocodes-view__category-panel-icon"
                        :class="{ 'promocodes-view__category-panel-icon_rotate': promocode.brands.isOpen }"
                        name="arrow-down"
                        width="8"
                        height="8"
                      />
                    </button>

                    <ul
                      v-if="promocode.brands.isOpen"
                      class="promocodes-view__category-panel-list"
                    >
                      <li v-for="brand in promocode.brands.items" :key="brand.id">
                        - {{ brand.name }}
                      </li>
                    </ul>
                  </template>
                  <template v-else-if="promocode.discountType === promocodeDiscountType.CATEGORY">
                    <button
                      class="promocodes-view__category-panel-header-btn"
                      @click="onToggleIsOpen(promocode.categories)"
                    >
                      Категории: &nbsp;
                      <SvgIcon
                        class="promocodes-view__category-panel-icon"
                        :class="{ 'promocodes-view__category-panel-icon_rotate': promocode.categories.isOpen }"
                        name="arrow-down"
                        width="8"
                        height="8"
                      />
                    </button>

                    <ul
                      v-if="promocode.categories.isOpen"
                      class="promocodes-view__category-panel-list"
                    >
                      <li v-for="category in promocode.categories.items" :key="category.id">
                        - {{ category.name }}
                      </li>
                    </ul>
                  </template>
                </div>
              </template>
            </td>
          </tr>
          <tr v-if="isLoading">
            <td colspan="5">
              <LoaderDots class="profile-promocodes-table__loading" />
            </td>
          </tr>
        </tbody>
      </table>
      <LoaderDots v-else-if="isLoading" class="profile-promocodes-table__loading" />
      <Alert v-else :closable="false">
        <template #title>
          У вас пока нет активированных промокодов
        </template>
      </Alert>
    </div>
    <MessagesModal
      v-show="isShowRequestModal"
      subject="Запрос промокода"
      alert-text="Запрос отправлен, администратор свяжется с вами в ближайшее время."
      @close="hideRequestModal"
    />
    <CustomModal
      v-show="resultModal.isShow"
      title="Уведомление"
      @close="hideResultModal"
      @closeOverlay="hideResultModal"
    >
      <template #content>
        {{ resultModal.message }}
      </template>
    </CustomModal>
  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import MessagesModal from '~/components/profile/messages/Modal';
import LoaderDots from '~/components/elements/LoaderDots';
import Alert from '~/components/elements/Alert';
import Tabs from '~/components/elements/Tabs';
import TabItem from '~/components/elements/TabItem';
import Button from '~/components/elements/Button';

import {promocodeType, promocodeDiscountType} from '~/plugins/enums/checkout';
import {saveToClipboard} from '~/plugins/helpers/file';
import CustomModal from '~/components/elements/CustomModal';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'ProfilePromocodes',
  components: {CustomModal, Button, TabItem, Tabs, Alert, LoaderDots, MessagesModal},
  mixins: [pageMeta],
  asyncData() {
    return {
      pageTitle: 'Мои промокоды',
    };
  },
  data() {
    return {
      items: [],

      isArchive: 0,
      isShowRequestModal: false,
      promocodeType,
      promocodeDiscountType,

      resultModal: {
        message: '',
        isShow: false,
      },
    }
  },
  async fetch() {
    await this.getPromocodeData(this.isArchive)
    this.items = this.promocodes

  },
  computed: {
    ...mapGetters({
      promocodes: 'promocodes/promocodes',
    }),
    isLoading() {
      return this.$fetchState.pending;
    },
  },
  watch: {
    promocodes(value) {
      this.items = value || []
    },
    async isArchive() {
      await this.getPromocodeData(this.isArchive)
    },
  },
  methods: {
    ...mapActions('promocodes', ['getPromocodeData']),
    switchIsArchive(value) {
      this.isArchive = value;
    },
    showRequestModal() {
      this.isShowRequestModal = true;
    },
    hideRequestModal() {
      this.isShowRequestModal = false;
    },
    showResultModal() {
      this.resultModal.isShow = true;
    },
    hideResultModal() {
      this.resultModal.isShow = false;
    },
    onToggleIsOpen(item) {
      item.isOpen = !item.isOpen;
    },
    onCopyToClipboard(text) {
      const result = saveToClipboard(text);
      this.resultModal.message = result ? 'Успешно скопировано' : 'Не удается скопировать';
      this.showResultModal()
    },
  },
}
</script>
