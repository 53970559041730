<template>
  <div
    :data-active="active"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
    @drop.prevent="onDrop"
  >
    <slot :dropZoneActive="active"></slot>
  </div>
</template>

<script>
export default {
  name: 'CustomDropzoneWrapper',
  data() {
    return {
      active: false,
      inActiveTimeout: null,
      events: ['dragenter', 'dragover', 'dragleave', 'drop'],
    }
  },
  mounted() {
    this.events.forEach((eventName) => {
      document.body.addEventListener(eventName, this.preventDefaults)
    })
  },
  beforeDestroy() {
    clearTimeout(this.inActiveTimeout)

    this.events.forEach((eventName) => {
      document.body.removeEventListener(eventName, this.preventDefaults)
    })
  },
  methods: {
    preventDefaults(e) {
      e.preventDefault()
    },
    setActive() {
      this.active = true
      clearTimeout(this.inActiveTimeout)

      this.$emit('active', this.active)
    },
    setInactive() {
      this.inActiveTimeout = setTimeout(() => {
        this.active = false
        this.$emit('active', this.active)
      }, 50)
    },
    onDrop(e) {
      this.setInactive()
      this.$emit('filesDropped', [...e.dataTransfer.files])
    },
  },
}
</script>
