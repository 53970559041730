<template>
  <CustomDropzoneWrapper v-slot="{ dropZoneActive }" class="dropzone" @filesDropped="addFilesHandler" @active="activeHandler">
    <CustomDropzoneInput :dropzone="dropzone" :is-valid="isValid" @filesChange="addFilesHandler">
      <slot name="icon" />
      <span v-if="dropzone.defaultText" class="dropzone__description">
        <template v-if="!isValid">
          <span class="font_red">Ошибка</span>
          <span class="dropzone__description_smaller font_red">
            {{ fileErrors[0] }}
          </span>
        </template>
        <template v-else>
          <template v-if="dropZoneActive">
            <span>Отпустите</span>
            <span class="dropzone__description_smaller">
              чтобы добавить файлы
            </span>
          </template>
          <template v-else>
            <span>Перетащите сюда Ваши файлы</span>
            <span class="dropzone__description_smaller">
              или нажмите для выбора
            </span>
          </template>
        </template>
      </span>
    </CustomDropzoneInput>
    <CustomDropzonePreview v-if="fileList.length" :files="fileList" @remove="removeFileHandler" />
  </CustomDropzoneWrapper>
</template>

<script>
import CustomDropzoneWrapper from '~/components/elements/CustomDropzone/Wrapper';
import CustomDropzoneInput from '~/components/elements/CustomDropzone/Input';
import CustomDropzonePreview from '~/components/elements/CustomDropzone/Preview';

import { addFiles, removeFile } from '~/components/elements/CustomDropzone/helpers';

export default {
  name: 'CustomDropzone',
  components: {CustomDropzonePreview, CustomDropzoneWrapper, CustomDropzoneInput },
  props: {
    options: {
      type: Object,
      required: true,
    },
    fileList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dropzone: {},
      defaultOptions: {
        accept: '',
        fileSize: 0, // Kb
        defaultText: true,
        fileNum: 0,
      },
      isValid: true,
      validTimeout: null,
      fileErrors: [],
    }
  },
  created() {
    Object.assign(this.dropzone, this.defaultOptions, this.options)
  },
  beforeDestroy() {
    clearTimeout(this.validTimeout)
  },
  methods: {
    addFiles,
    removeFile,
    addFilesHandler(files) {
      const { valid, errors } = this.addFiles(this.fileList, files, this.dropzone)

      this.isValid = valid
      this.fileErrors = errors

      clearTimeout(this.validTimeout)

      if (!this.isValid) {
        this.validTimeout = setTimeout(() => {
          this.isValid = true
        }, 3000)
      }
    },
    removeFileHandler(file) {
      this.removeFile(this.fileList, file)
    },
    activeHandler(state) {
      this.$emit('active', state)
    },
  },
}
</script>
