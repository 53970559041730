<template>
  <CustomModal empty @close="$emit('close')" @closeOverlay="$emit('close')">
    <template #content>
      <div v-if="contentType === 'message'" class="profile-messages__modal">
        <div class="profile-messages__header">
          <div class="profile-messages__title font font_title-m font_medium">
            Новое сообщение
          </div>
          <button class="profile-messages__close-button" @click="$emit('close')">
            <svg-icon name="close" width="15" height="15" />
          </button>
        </div>
        <CustomInput
          v-if="subject"
          placeholder="Введите тему"
          label-class="font font_m font_bold"
          class="profile-messages__input profile-messages__subject"
          :value="messageSubject"
          disabled
        >
          Тема
        </CustomInput>
        <CustomInput
          v-else
          v-model="messageSubject"
          placeholder="Введите тему"
          class="profile-messages__input profile-messages__subject"
          label-class="font font_m font_bold"
        >
          Тема
        </CustomInput>
        <CustomInput
          v-model="messageBody"
          placeholder="Текст сообщения"
          label-class="font"
          class="profile-messages__input profile-messages__message"
          tag="textarea"
          rows="3"
        >
          Сообщение
        </CustomInput>
        <CustomDropzone :options="dropzoneOptions" :file-list="messageFiles" />
        <div class="font font_red">
          {{ messageError }}
        </div>
        <Button class="profile-messages__accept-button" :disabled="isSendDisabled" theme="sm" @click="sendMessage">
          <Spinner v-if="isMessageSending" theme="simple" width="24px" height="24px" show />
          <template v-else>
            Отправить сообщение
          </template>
        </Button>
      </div>

      <div v-else-if="contentType === 'alert-success'" class="profile-messages profile-messages__modal">
        <div class="profile-messages__header">
          <h2 class="profile-messages__title font font_title-m font_bold font_uppercase">
            Уведомление
          </h2>
          <button class="profile-messages__close-button" @click="$emit('close')">
            <svg-icon name="close" width="16" height="14" />
          </button>
        </div>
        <p class="profile-messages__alert-text">
          {{ alertText }}
        </p>
        <Button class="profile-messages__accept-button" @click="closeHandler">
          Закрыть
        </Button>
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/elements/CustomModal';
import CustomInput from '@/components/elements/CustomInput';
import Button from '@/components/elements/Button';
import CustomDropzone from '~/components/elements/CustomDropzone';
import Spinner from '~/components/elements/LoaderSpinner';

export default {
  name: 'ProfileMessagesModal',
  components: {
    Spinner,
    CustomDropzone,
    CustomModal,
    CustomInput,
    Button,
  },
  props: {
    subject: {
      type: String,
      default: null,
    },
    alertText: {
      type: String,
      default: 'Сообщение отправлено',
    },
  },
  data() {
    return {
      messageSubject: '',
      messageBody: '',
      contentType: 'message',
      isMessageSending: false,
      dropzoneOptions: {
        accept: '.doc,.docx,.xls,.xlsx,.jpeg,.jpg,.webp,.png,.mp4,.mpeg,.mp3,.txt,.zip,.pdf',
        fileSize: 10000,
      },
      messageError: '',
      errorTimeout: null,
      messageFiles: [],
    };
  },
  computed: {
    isSendDisabled() {
      return this.isMessageSending || !this.messageSubject || !this.messageBody
    },
  },
  watch: {
    messageError() {
      if (this.messageError) {
        this.errorTimeout = setTimeout(() => {
          this.messageError = null
        }, 5000)
      }
    },
  },
  mounted() {
    if(this.subject) {
      this.messageSubject = this.subject
    }
  },
  beforeDestroy() {
    this.clearModalData()
    this.errorTimeout && clearTimeout(this.errorTimeout)
  },
  methods: {
    prepareFormData(data) {
      const formData = new FormData();

      Object.keys(data).forEach(key => {
        formData.append(key, data[key])
      })

      return formData
    },
    async sendMessage() {
      try {
        const data = this.prepareFormData({
          theme: this.messageSubject,
          message: this.messageBody,
        })

        if (this.messageFiles.length) {
          this.messageFiles.forEach(item => data.append('files[]', item.file))
        }

        this.isMessageSending = true

        await this.$api.profile.sendMessage(data);
        this.contentType = 'alert-success';
        this.$emit('messageSent');
      } catch (err) {
        this.errorTimeout && clearTimeout(this.errorTimeout)
        this.messageError = err?.response?.data?.message || '';
      } finally {
        this.isMessageSending = false
      }
    },
    clearModalData() {
      this.messageSubject = ''
      this.messageBody = ''
      this.contentType = 'message'

      this.messageFiles = []
    },
    closeHandler() {
      this.clearModalData()
      this.$emit('close')
    },
  },
};
</script>
