import {UploadableFile} from '~/components/elements/CustomDropzone/helpers/UploadableFile';
import {phraseDeclension} from '~/plugins/formatting';

const errorMessage = 'Файл(ы) не удовлетворяют требованиям'

export function addFiles(files, newFiles, options) {
  const errors = []

  const upFiles = newFiles
    .filter((file) => {
      const { valid, message } = validation(file, files.length, newFiles.length, options)

      if (!valid) {
        errors.push(message)
      }

      return valid
    })
    .map((file) => new UploadableFile(file))
    .filter((file) => !fileExists(files, file.id))

  upFiles.forEach(file => files.push(file))

  return {
    valid: newFiles.length === upFiles.length,
    errors,
  }
}

function fileExists(files, otherId) {
  return files.some(({ id }) => id === otherId)
}

function validation(file, len, newLen, options) {
  const { accept, fileSize, fileNum } = options

  if (fileNum && len + newLen > fileNum) {
    return prepareValidation(false, `Доступно для загрузки ${phraseDeclension(fileNum, ['файл', 'файлов', 'файла'])}`)
  }

  const ext = file.name.match(/\.([^.]*?)(?=\?|#|$)/)[0]

  if (!ext || (file.size / 1000) > fileSize) return prepareValidation(false, errorMessage)

  const isValid = accept.includes(ext)

  return prepareValidation(isValid, !isValid ? errorMessage : '')
}

function prepareValidation(valid, message) {
  return { valid, message }
}
